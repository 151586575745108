<template>
    <!-- Modal -->
    <div class="modal fade" id="supplier-stock" tabindex="-1" role="dialog" aria-labelledby="formsLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <span class="d-block">
                        <h5 class="content-title-min">Add Stock</h5>
                        <p class="text-muted">Let us know what medicine/equipment you can supply</p>
                    </span>
                </div>
                            
                <div class="modal-body pt-0">
                    <div class="row">
                        <div class="col-md-12 col-sm-12">
                            <status v-model:status_data=status_data></status>

                            <div class="form-row mt-1">
                                <label for="names" class="fw-bold mb-2">Choose Stock</label>  
                                <div class="input-group">
                                    <span class="input-group-text border-right-0">
                                        <font-awesome-icon id="eye" :icon="['fa', 'user']" />
                                    </span>

                                    <select v-model="stock_id" id="" class="form-select">
                                        <option></option>
                                        <option :value="id" v-for="({name, id}, index) in stocks" :key=index>{{ name }}</option>
                                    </select>
                                </div>
                            </div>                           

                            <button class="btn bg-orange text-uppercase text-white mt-4 w-100" @click="handleSubmit()">
                                <span class="m-0" style="letter-spacing: 1px">Add</span> <font-awesome-icon class="ms-3" :icon="['fas', 'plus']"></font-awesome-icon>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
 
<style lang="scss" scoped>
    @import '../../assets/scss/layout/_form.scss';
    @import '../../assets/scss/layout/_lists.scss';
    @import '../../assets/scss/helpers/_breakpoints.scss';

    .form-select {
        border: 0;
        border-bottom: 1px solid #eee;
    }
</style>

<script>
    import Status from '../../components/Status';

    export default {
        name: 'ChooseStock',
        props: {
            supplier_id: {
                required: true,
                type: Number
            }
        },
        components: {
            Status
        },
        data() {
            return {
                screen: 0,
                stock_id: null,
                status_data: {
                    "success": false,
                    "success_message": null,
                    "error": false,
                    "error_message": null,
                    "loading": false
                },
                stocks: {},
            }
        },
        mounted() {
            this.getStocks()
        },
        methods: {
            getStocks() {
                this.axios.get('api/stocks').then(response => {
                    this.stocks = response.data.data
                }).catch((errors) => {
                    console.log(errors);
                })  
            },
            handleSubmit() {
                this.status_data.loading = true
                
                
                this.axios.post('api/supplier-stocks', {
                    supplier_id: this.supplier_id,
                    stock_id: this.stock_id
                }).then(() => {
                    this.status_data.loading = false
                    this.status_data.error = false
                    this.status_data.success = true
                    this.status_data.success_message = 'Stock Added!'
                }).catch((error) => {
                    this.status_data.loading = false
                    this.status_data.error = true
                    this.status_data.success = false
                    this.status_data.error_message = error.response.data.data

                    console.log(error);
                })
            },
            nextScreen() {
                this.screen++
            },
            previousScreen() {
                if(this.screen > 0) {
                    this.screen--
                }
            },
        }
    }
</script>